<template>
    <header class="flex items-center justify-between w-full px-4 py-6 space-x-4 font-medium text-gray-600 lg:px-12">
        <a href=".">
            <img src="/images/logo-black.svg" alt="Webdevs.com.au Logo" class="h-12">
        </a>

        <!-- desktop menu -->
        <nav class="hidden lg:block">
            <ul class="flex items-center space-x-6 2xl:space-x-8">
                <StoryblokComponent
                        v-for="blok in headerMenu.navigationItems"
                        :key="blok._uid"
                        :blok="blok"
                />
            </ul>
        </nav>
        <!-- desktop menu -->

        <div class="items-center hidden space-x-4 lg:flex xl:space-x-8">
            <StoryblokComponent :key="headerMenu.action._uid" :blok="headerMenu.action[0]"/>
        </div>

        <!-- mobile menu -->
        <div class="relative lg:hidden" x-data="{ open: false }">
            <!-- toggle menu -->
            <button type="button" class="px-4 py-2 bg-white border-2 rounded-full border-zinc-100"
                    x-on:click="open = !open">
                <span class="sr-only">Open Menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-zinc-500" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M4 6h16M4 12h16M4 18h16"/>
                </svg>
            </button>
            <!--/ toggle menu -->

            <!-- menu overlay -->
            <div
                    class="fixed inset-0 z-50 p-4 transition-all origin-top-right bg-gradient-to-b from-gray-300 to-transparent bg-opacity-20"
                    x-show="open"
                    x-transition:enter="ease-out duration-50"
                    x-transition:enter-start="opacity-0"
                    x-transition:enter-end="opacity-100"
                    x-transition:leave="transition ease-in duration-100"
                    x-transition:leave-end="opacity-0 "
            >
                <div
                        class="relative transition-all origin-top-right bg-white rounded shadow"
                        x-transition:enter="ease-out duration-150 scale-75"
                        x-transition:enter-start="opacity-0 -translate-y-2 scale-75"
                        x-transition:enter-end="opacity-100 translate-y-0 scale-100"
                        x-transition:leave="transition ease-in duration-200"
                        x-transition:leave-end="opacity-0 -translate-y-2"
                        x-on:click.away="open = false"
                >
                    <button type="button" class="absolute top-4 right-4" x-on:click="open = false">
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-zinc-500" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                    <nav class="p-6">
                        <span class="block mb-4 text-xs font-bold tracking-widest uppercase text-slate-500">Services</span>
                        <ul class="grid grid-cols-2 gap-2 text-sm font-semibold md:grid-cols-4 text-slate-700 ">
                            <StoryblokComponent
                                    v-for="blok in headerMenu.navigationItems"
                                    :key="blok._uid"
                                    :blok="blok"
                            />
                        </ul>
                    </nav>
                </div>
            </div>
            <!--/ menu overlay-->
        </div>
    </header>
</template>

<script setup>
const storyblokApi = useStoryblokApi();
const config = useRuntimeConfig();

const {data} = await storyblokApi.get('cdn/stories/globals/header', {
    version: config.STORYBLOK_STAGE,
    resolve_links: 'link',
})

const headerMenu = ref(null)
headerMenu.value = data.story.content
const logo = config.LOGO_DARK;

</script>
